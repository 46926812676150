import React from "react";
import { Link } from "gatsby";
import hero from "../../../images/home/hero.svg";
const Hero = () => {
  return (
    <section className="hero ">
      <div className="hero__space">
        <h1 className="hero__title">
          アジャイル開発に必須の <br /> CI/CDプラットフォーム
        </h1>
        <p className="hero__description">
          ビルド・テスト・デプロイ・検証までの全工程を{" "}
          <br className="hidden md:block" /> AIとビジュアルなUIで支援。
        </p>
        <Link href="/inquiry/" className="hero__btn">
          お問い合わせ
        </Link>
      </div>
      <div className="hero__space">
        <div className="hero__video">
          <img
            src={hero}
            alt="hero harness"
            loading="lazy"
            className="object-cover"
          />
        </div>
      </div>
    </section>
  );
};
export default Hero;
