import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import arrow from "@images/home/arrow.svg";

const Features = () => {
  return (
    <section className="features">
      <div className="feature__card">
        <div className="feature__card__wrapper">
          <h2 className="feature__card_title">
            <span>
              <svg
                className="w-[40px] aspect-square my-auto"
                viewBox="0 0 46 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M41.2465 9.35662C44.0535 10.946 45.7774 13.9329 45.7497 17.1583L45.6149 32.7939C45.5877 35.9386 43.8991 38.8342 41.1755 40.4063L27.172 48.4891C24.4484 50.0611 21.0964 50.075 18.36 48.5255L4.75347 40.8213C1.94652 39.232 0.222527 36.2451 0.250331 33.0195L0.385109 17.3839C0.412213 14.2393 2.10089 11.3437 4.82448 9.77164L18.828 1.68887C21.5516 0.116819 24.9036 0.102959 27.64 1.65241L41.2465 9.35662ZM36.2958 21.4966C35.5597 24.2714 32.7446 25.9373 29.9926 25.2432L16.8873 21.698C14.7289 21.1136 12.4824 21.4129 10.5519 22.538C8.62173 23.6627 7.24158 25.4771 6.66379 27.6561C6.08538 29.8353 6.38139 32.1105 7.50166 34.0691C8.62191 36.0275 10.426 37.4239 12.5851 38.008C14.7435 38.5923 16.9901 38.293 18.9203 37.1682C20.8507 36.0432 22.2308 34.2289 22.8086 32.05L23.2656 30.2189C23.3233 29.9878 23.1854 29.7529 22.9553 29.6907L20.7538 29.0949C20.5172 29.0309 20.2743 29.1735 20.215 29.4113L19.7682 31.2031C19.0229 34.0076 16.1555 35.6775 13.3782 34.9267C10.6092 34.1769 8.96292 31.2987 9.7042 28.5032C10.4403 25.7284 13.2559 24.0613 16.0078 24.7553L29.1127 28.3019C31.2711 28.8863 33.5176 28.5869 35.4481 27.4619C37.3783 26.3371 38.7584 24.5229 39.3362 22.3439C39.9147 20.1645 39.6186 17.8894 38.4983 15.9309C37.3781 13.9723 35.574 12.576 33.4149 11.9919C31.2565 11.4075 29.0099 11.7069 27.0797 12.8318C25.1493 13.9567 23.7692 15.7709 23.1914 17.9499L22.7591 19.3975C22.6886 19.6332 22.8264 19.8808 23.064 19.945L25.2656 20.5409C25.4945 20.6029 25.7311 20.4711 25.799 20.2439L26.2318 18.7969C26.9771 15.9923 29.8445 14.3223 32.6218 15.0733C35.3908 15.823 37.0371 18.7013 36.2958 21.4966Z"
                  fill="url(#paint0_linear_1_1122)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1_1122"
                    x1="6.85397"
                    y1="0.499998"
                    x2="32.625"
                    y2="49.5"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#62F91F" />
                    <stop offset="1" stop-color="#45BD35" />
                  </linearGradient>
                </defs>
              </svg>
            </span>
            Continuous Delivery (CD)
          </h2>
          <p className="feature__card__description">
            エンジニアが、高速で再現性があり安全なパイプラインを使用して、オンデマンドで本番環境にデプロイできるようにします。
          </p>

          <div className="feature__card__space">
            <h3 className="feature__card__sub_title">
              数分でブルー／グリーン／カナリアデプロイメントを構築
            </h3>
            <p className="feature__card__description">
              ビジュアルビルダーやネイティブのGitOpsエクスペリエンスを使
              い、エンジニアリングチーム全体で複雑なデプロイメントを構築・テンプレート化・スケーリングできるようにします。
            </p>
          </div>
          <div className="feature__card__space">
            <h3 className="feature__card__sub_title">
              デプロイメントの自動検証とロールバック
            </h3>
            <p className="feature__card__description">
              デプロイメントごとに性能と品質の低下を自動検出し、必要なら数秒で以前の作業バージョンにロールバックします。
            </p>
          </div>
          <div className="feature__card__space">
            <h3 className="feature__card__sub_title">
              クラウドネイティブと従来のアプリの双方をサポート
            </h3>
            <p className="feature__card__description">
              Harnessは、マイクロサービス用コンテナ、Helm、Kubernetes、ECSに加え、WebLogicやWebSphereなどのサーバレススタックと「ビンテージ」スタックをサポートしています。
            </p>
          </div>
          <Link className="feature__card__btn" to="/CD/">
            CD製品詳細へ
            <img
              className="w-[30px] object-contain aspect-square"
              objectFit="contain"
              src={arrow}
              alt="more"
            />
          </Link>
        </div>
        <div className="feature__card__wrapper">
          <StaticImage
            className="w-full md:my-auto"
            src="../../../images/home/cd_image.png"
            alt="CD"
          />
          {/* <StaticImage
            className="w-full md:my-auto"
            layout="fullWidth"
            src="../../../images/home/cd_image.png"
            alt="CD"
          /> */}
        </div>
      </div>
      <div className="feature__card feature__card--rev">
        <div className="feature__card__wrapper">
          <h2 className="feature__card_title">
            <span>
              <svg
                className="w-[40px] aspect-square my-auto"
                viewBox="0 0 46 46"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M45.75 23C45.75 25.8066 45.2057 28.5355 44.2548 31.0486C44.0802 31.51 43.491 31.626 43.14 31.2792L36.1592 24.3825C35.8678 24.0947 35.8191 23.6454 36.0089 23.2827C36.7661 21.8358 37.1446 20.1807 37.1446 18.45C37.2435 12.8119 32.5946 8.16304 26.8576 8.16304C25.0362 8.16304 23.3669 8.61937 21.9164 9.39872C21.5593 9.59052 21.1149 9.54474 20.8264 9.25998L14.6122 3.12644C14.1743 2.6942 14.3082 1.9578 14.8825 1.73725C17.4094 0.76702 20.0939 0.25 23 0.25C35.5619 0.25 45.75 10.4381 45.75 23ZM9.83216 4.48608C10.2377 4.20028 10.7879 4.2597 11.1388 4.61052L18.2005 11.6723C18.5126 11.9844 18.5399 12.4777 18.295 12.8448C17.1913 14.4995 16.5706 16.4466 16.5706 18.549C16.5706 24.2859 21.2196 28.9347 26.9565 28.9347C29.0588 28.9347 31.0059 28.3143 32.6606 27.2105C33.0276 26.9655 33.5211 26.9928 33.8332 27.3049L41.4769 34.9487C41.8329 35.3046 41.8881 35.8649 41.5907 36.271C37.3933 42.0053 30.699 45.75 23 45.75C10.4381 45.75 0.25 35.5619 0.25 23C0.25 15.2943 4.00141 8.5949 9.83216 4.48608ZM26.9565 24.9782C30.8141 24.9782 33.8804 21.9119 33.8804 18.0544C33.8804 14.1968 30.8141 11.1304 26.9565 11.1304C23.099 11.1304 20.0326 14.1968 20.0326 18.0544C20.0326 21.9119 23.099 24.9782 26.9565 24.9782Z"
                  fill="url(#paint0_linear_1_1148)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1_1148"
                    x1="-14.4469"
                    y1="14.9469"
                    x2="14.9469"
                    y2="60.4469"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#73DFE7" />
                    <stop offset="1" stop-color="#0095F7" />
                  </linearGradient>
                </defs>
              </svg>
            </span>
            Continuous Integration（CI）
          </h2>
          <p className="feature__card__description">
            スクリプト、依存関係、およびメンテナンスのエンジニアリング上の手間を要さないセルフサービスのクラウドネイティブCIです。
          </p>

          <div className="feature__card__space">
            <h3 className="feature__card__sub_title">ネイティブGitOps</h3>
            <p className="feature__card__description">
              グラフィカルなエディター（Visual Pipeline Builder）とAI{"&"}
              機械学習により、初心者でもCIパイプラインを構築できます。
            </p>
          </div>
          <div className="feature__card__space">
            <h3 className="feature__card__sub_title">
              Visual Pipeline Builder
            </h3>
            <p className="feature__card__description">
              複雑なCIパイプラインを初心者でも数分で構築し、可視化できます。
            </p>
          </div>
          <div className="feature__card__space">
            <h3 className="feature__card__sub_title">AI＆ML</h3>
            <p className="feature__card__description">
              ビルドとテストのサイクルの80％を自動化します。待ち時間を減らしコード開発にかけられる時間を増やします。
            </p>
          </div>
          <Link to="/CI/" className="feature__card__btn">
            CI製品詳細へ
            <img
              className="w-[30px] object-contain aspect-square"
              objectFit="contain"
              src={arrow}
              alt="more"
            />
          </Link>
        </div>
        <div className="feature__card__wrapper">
          <StaticImage
            className="w-full md:my-auto"
            src="../../../images/home/ci_image.png"
            alt="CD"
          />
        </div>
      </div>
      <div className="feature__card ">
        <div className="feature__card__wrapper">
          <h2 className="feature__card_title">
            <span>
              <svg
                className="w-[42px] aspect-square my-auto"
                viewBox="0 0 54 38"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M26.7189 36.4666C26.7189 36.9497 27.1107 37.3416 27.5939 37.3416H41.6968H41.7374H41.778H42.1962V37.3326C48.3419 37.0918 53.2503 32.0335 53.2503 25.8287C53.2503 19.7919 48.6042 14.8403 42.6927 14.3548C42.2732 14.3204 41.9192 14.0164 41.8315 13.6047C40.2349 6.11638 33.5821 0.5 25.6175 0.5C16.4614 0.5 9.0388 7.92252 9.0388 17.0787C9.0388 17.1753 8.96988 17.2583 8.87519 17.2773C4.24073 18.2083 0.75 22.3015 0.75 27.21C0.75 32.8054 5.28575 37.3413 10.881 37.3414L22.5374 37.3416C23.0206 37.3416 23.4124 36.9497 23.4124 36.4666V33.722C23.4124 33.281 23.0832 32.9125 22.6502 32.8295C20.8281 32.4801 19.2682 31.728 18.2006 30.6519C17.2454 29.6894 16.6751 28.4971 16.4979 27.0966C16.4366 26.612 16.8387 26.2123 17.3271 26.2145L19.2761 26.2236C19.7438 26.2258 20.1163 26.5998 20.223 27.0552C20.6707 28.968 22.4018 30.2279 24.9665 30.2398C25.9658 30.2445 29.6919 29.7776 29.704 27.2023C29.7135 25.1425 27.0056 24.3613 24.4436 23.7291C24.0069 23.6214 23.5619 23.5114 23.1171 23.3921C20.2192 22.6224 17.1539 21.7415 17.1712 18.034C17.188 14.4327 20.2192 12.5751 23.4124 12.1969V8.7432C23.4124 8.26006 23.8042 7.8682 24.2874 7.8682H25.8407C26.3239 7.8682 26.7157 8.26006 26.7157 8.7432V12.2907C29.0185 12.6625 32.1198 13.8283 32.6456 17.378C32.7179 17.8655 32.3136 18.274 31.8208 18.2716L29.8623 18.2625C29.4129 18.2604 29.0507 17.9126 28.9165 17.484C28.4087 15.8623 26.8165 14.8794 24.4849 14.8685C22.934 14.8612 20.6861 15.6841 20.6773 17.5638C20.6695 19.2673 21.9732 19.8477 26.8383 21.1046L27.4824 21.2691C27.5638 21.2893 27.6507 21.3102 27.7423 21.3323C29.5956 21.7793 33.3986 22.6964 33.3795 26.7734C33.3645 29.9918 31.1658 32.1932 27.4918 32.8484C27.0538 32.9265 26.7189 33.2968 26.7189 33.7417V36.4666Z"
                  fill="url(#paint0_linear_1_1172)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1_1172"
                    x1="9.18752"
                    y1="-2.31247"
                    x2="32.725"
                    y2="48.5717"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#00CFDE" />
                    <stop offset="1" stop-color="#05A660" />
                  </linearGradient>
                </defs>
              </svg>
            </span>
            クラウドコスト管理（CCM）
          </h2>
          <p className="feature__card__description">
            使用済み、アイドル、未割り当てのリソース、およびソフトウェアのデプロイ、構成の変更、自動スケーリングなどの変更によって、クラウドのコストを分析して関連付けます。
          </p>

          <div className="feature__card__space">
            <h3 className="feature__card__sub_title">
              クラウドとコンテナのコストの可視性
            </h3>
            <p className="feature__card__description">
              アプリケーション、マイクロサービス、クラスター、ネ一ムスぺ一スなどによる独自のクラウドコストを可視化します。{" "}
            </p>
          </div>
          <div className="feature__card__space">
            <h3 className="feature__card__sub_title">
              クラウドコストとイベントの相関を可視化
            </h3>
            <p className="feature__card__description">
              通常時だけでなく設定変更やスケーリングなどのイベント発生とコストの相関も調べることができます。{" "}
            </p>
          </div>
          <div className="feature__card__space">
            <h3 className="feature__card__sub_title">タグ付けは一切不要</h3>
            <p className="feature__card__description">
              手作業によるタグ付けをしなくても、詳細なコスト分析ができます。開発者の手を煩わせずに見通しを良くします。{" "}
            </p>
          </div>
          <Link to="/cost/" className="feature__card__btn">
            CCM製品詳細へ
            <img
              className="w-[30px] object-contain aspect-square"
              objectFit="contain"
              src={arrow}
              alt="more"
            />
          </Link>
        </div>
        <div className="feature__card__wrapper">
          <StaticImage
            className="w-full md:my-auto"
            src="../../../images/home/price_img.png"
            alt="CD"
          />
        </div>
      </div>
      <div className="feature__card feature__card--rev">
        <div className="feature__card__wrapper">
          <h2 className="feature__card_title">
            <span>
              <svg
                className="w-[45px] aspect-square my-auto"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.2901 2.59963C9.76196 1.97753 6.39756 4.33331 5.77547 7.8614L2.59963 25.8724C1.97753 29.4005 4.33331 32.7649 7.8614 33.387L17.2984 35.051C17.4663 35.0806 17.5919 34.9 17.5056 34.7529L8.6031 19.5702C8.23237 18.9837 8.23758 17.8421 8.66605 17.5701C8.7642 17.507 9.05577 17.2464 9.05577 17.2464L9.55404 16.7015L9.63586 16.6117C10.0981 16.1036 10.5157 15.6445 11.8355 14.9838L12.1195 14.8849C14.0554 14.2803 15.1318 14.324 16.2083 14.3677C17.2853 14.4114 18.3624 14.4551 20.3001 13.8498C21.8904 13.3531 23.3368 12.3777 24.1429 11.766C24.5008 11.4944 25.0292 11.5737 25.2671 11.9548L31.013 21.1558C31.1917 21.442 31.147 21.812 30.8859 22.0257C30.2652 22.5334 29.0107 23.4283 27.3439 23.9489C25.408 24.5535 24.3036 24.5273 23.1991 24.5011C22.0942 24.4748 20.9891 24.4485 19.0513 25.0538C17.345 25.5868 16.6641 26.2904 16.4372 26.5941C16.3902 26.6569 16.3659 26.8017 16.4037 26.8704C16.8601 27.7013 19.315 32.0216 21.2235 35.3759C21.3708 35.6347 21.6255 35.814 21.9188 35.8657L25.8724 36.5629C29.4005 37.185 32.7649 34.8292 33.387 31.3011L36.5629 13.2901C37.185 9.76196 34.8292 6.39756 31.3011 5.77547L13.2901 2.59963Z"
                  fill="url(#paint0_linear)"
                />
                <path
                  d="M20.8081 16.3429C19.1788 16.768 18.1916 16.737 17.2045 16.7061C16.2177 16.6751 15.2311 16.6442 13.6035 17.0688C12.6597 17.3425 12.1151 17.823 11.5965 18.436C11.5415 18.5009 11.5351 18.5939 11.5794 18.6666L14.7307 23.833C14.7951 23.9386 14.9397 23.9624 15.0377 23.8869C15.4717 23.5527 16.6346 22.7383 18.1085 22.3537C19.9445 21.8747 21.0435 21.8854 22.2006 21.8966C23.0968 21.9053 24.0279 21.9143 25.3634 21.6965C26.2592 21.5504 26.9905 21.161 27.3981 20.902C27.6054 20.7702 27.6498 20.4989 27.5166 20.2926L24.3397 15.375C24.1911 15.145 23.8787 15.0807 23.6405 15.2155C23.1179 15.5113 22.1361 15.9964 20.8081 16.3429Z"
                  fill="url(#paint1_linear)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear"
                    x1="8.54646"
                    y1="2.5"
                    x2="29.7256"
                    y2="35.2842"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#FFC100" />
                    <stop offset="1" stop-color="#E66B35" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear"
                    x1="8.54646"
                    y1="2.5"
                    x2="29.7256"
                    y2="35.2842"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#FFC100" />
                    <stop offset="1" stop-color="#E66B35" />
                  </linearGradient>
                </defs>
              </svg>
            </span>
            Feature Flags
          </h2>
          <p className="feature__card__sub__description">
            新機能実装支援機能により、最小限のリスクでの新機能の追加実装を可能にします。
          </p>

          <div className="feature__card__space">
            <h3 className="feature__card__sub_title">Feature Workflows</h3>
            <p className="feature__card__sub__description">
              ロールアウトや承認、機能検証をスケジュール化し、ターゲット顧客層での新機能の稼働状況を確認できるようにします。
            </p>
          </div>
          <div className="feature__card__space">
            <h3 className="feature__card__sub_title">Feature Verifications</h3>
            <p className="feature__card__sub__description">
              ロールアウト前に性能やエンドユーザーのUXには影響のない形で機能を検証できるようにします。
            </p>
          </div>
          <div className="feature__card__space">
            <h3 className="feature__card__sub_title">Feature Canvas</h3>
            <p className="feature__card__sub__description">
              新規コードのマージからロールアウトまでの過程を統合ビューで目視できるようにします。
              <br /> <br /> ※この機能は現在β版として提供されています。
            </p>
          </div>
          <Link to="/feature-flags/" className="feature__card__btn">
            Feature Flags製品詳細へ
            <img
              className="w-[30px] object-contain aspect-square"
              objectFit="contain"
              src={arrow}
              alt="more"
            />
          </Link>
        </div>
        <div className="feature__card__wrapper">
          <StaticImage
            className="w-full md:my-auto"
            src="../../../images/home/feature_img.png"
            alt="Feature Img"
          />
        </div>
      </div>
      <div className="feature__card ">
        <div className="feature__card__wrapper">
          <h2 className="feature__card_title">
            <div>
              <StaticImage
                quality={100}
                width={45}
                layout="fixed"
                src={"../../../images/home/STOLogo.png"}
                alt="Security Testing Orchestration Icon"
              />
            </div>
            Security Testing Orchestration
          </h2>
          <p className="feature__card__description">
            優先順位付けされたセキュリティー脆弱性データで開発者を強化。デプロイメント速度を維持し、手戻りを最小限に抑えながら、安全性の高いアプリケーションのデリバリーを実現します。
          </p>

          <div className="feature__card__space">
            <h3 className="feature__card__sub_title">
              コードからデプロイまでのセキュリティー
            </h3>
            <p className="feature__card__description">
              パイプラインの適切な段階で、適切なセキュリティスキャナーを自動的に実行します。
            </p>
          </div>
          <div className="feature__card__space">
            <h3 className="feature__card__sub_title">
              相関性のあるスキャナー結果
            </h3>
            <p className="feature__card__description">
              Harnessは、時間のかかるスキャナー出力解析をエンジニアリングチームに代わって実行します。
            </p>
          </div>
          <div className="feature__card__space">
            <h3 className="feature__card__sub_title">
              手作業による分析作業の時間短縮
            </h3>
            <p className="feature__card__description">
              Harness Security Testing
              Orchestrationは、スキャナーのデータにインテリジェンスを適用し、優先順位をつけた脆弱性リストと修復の推奨を実現します。
            </p>
          </div>
          <Link
            to="/security-testing-orchestration/"
            className="feature__card__btn"
          >
            STO製品詳細へ
            <img
              className="w-[30px] object-contain aspect-square"
              objectFit="contain"
              src={arrow}
              alt="more"
            />
          </Link>
        </div>
        <div className="feature__card__wrapper">
          <StaticImage
            className="w-full md:my-auto"
            src="../../../images/home/STOBanner.png"
            alt="Security Testing Orchestration"
            layout="constrained"
            width={752}
            objectFit="cover"
            quality={100}
          />
        </div>
      </div>
      <div className="feature__card feature__card--rev">
        <div className="feature__card__wrapper">
          <h2 className="feature__card_title">
            <div>
              <StaticImage
                quality={100}
                width={45}
                layout="fixed"
                src={"../../../images/home/SRMLogo.png"}
                alt="Service Reliability Management Icon"
              />
            </div>
            Service Reliability Management
          </h2>
          <p className="feature__card__sub__description">
            デプロイメントの速さと、プロダクション品質の環境における信頼性の必要性から、メリットを得ることができます。
          </p>
          <div className="feature__card__space">
            <h3 className="feature__card__sub_title">信頼性の継続的な改善</h3>
            <p className="feature__card__sub__description">
              パイプラインの適切な段階で、適切なチェックを自動的に実行します。
            </p>
          </div>
          <div className="feature__card__space">
            <h3 className="feature__card__sub_title">
              SLO重視のパイプラインガバナンス
            </h3>
            <p className="feature__card__sub__description">
              信頼性ガードレールは、優秀な開発者が信頼性の高いソフトウェアを高速に提供し続けることを保証します。
            </p>
          </div>
          <div className="feature__card__space">
            <h3 className="feature__card__sub_title">共同SLO管理</h3>
            <p className="feature__card__sub__description">
              パイプラインガバナンスコラボレーション、組織全体の透明性、速度と信頼性のバランスで利益を得ることができます。
            </p>
          </div>
          <Link
            to="/service-reliability-management/"
            className="feature__card__btn"
          >
            SRM製品詳細へ
            <img
              className="w-[30px] object-contain aspect-square"
              objectFit="contain"
              src={arrow}
              alt="more"
            />
          </Link>
        </div>
        <div className="feature__card__wrapper">
          <StaticImage
            quality={100}
            className="w-full md:my-auto"
            src={"../../../images/home/SRMBanner.png"}
            alt="Service Reliability Management"
          />
        </div>
      </div>
      <div className="feature__card mb-24 md:mb-0">
        <div className="feature__card__wrapper">
          <h2 className="feature__card_title">
            <div>
              <StaticImage
                quality={100}
                width={45}
                layout="fixed"
                src={"../../../images/home/CELogo.png"}
                alt="Chaos Engineering Icon"
              />
            </div>
            Chaos Engineering
          </h2>
          <p className="feature__card__description">
            コントロールされた「カオス」実験を行い、リアルワールドのストレスや障害がシステムにどのような影響を与えるかを理解できます。
          </p>

          <div className="feature__card__space">
            <h3 className="feature__card__sub_title">
              テストの完了からカオスをスタート
            </h3>
            <p className="feature__card__description">
              CI/CDパイプラインでのテストは、高品質のソフトウェアを作成するのに役立ちます。しかし、ソフトウェアが実際にデプロイされたときにシステムに影響を与える可能性のある信頼性ストレスは考慮されることがありません。
            </p>
          </div>
          <div className="feature__card__space">
            <p className="feature__card__description">
              カオスエンジニアリングは、デプロイメントとそれらが実行されるインフラストラクチャーを組み合わせ、現実世界の障害シナリオにシステムがどのように対応するかを実証することで、従来のテストを超えた成果を提供しします。
            </p>
          </div>
          <Link to="/chaos-engineering/" className="feature__card__btn">
            CE製品詳細へ
            <img
              className="w-[30px] object-contain aspect-square"
              objectFit="contain"
              src={arrow}
              alt="more"
            />
          </Link>
        </div>
        <div className="feature__card__wrapper">
          <StaticImage
            className="w-full md:my-auto"
            src="../../../images/home/CEBanner.png"
            alt="Chaos Engineering"
            layout="constrained"
            width={752}
            objectFit="cover"
            quality={100}
          />
        </div>
      </div>
    </section>
  );
};
export default Features;
