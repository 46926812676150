import React from "react";
import Articles from "@components/home/articles";
import Features from "@components/home/features";
import Description from "@components/home/description";
import Hero from "@components/home/hero";
import Container from "@layout/container";
import Meta from "@components/seo/Meta";

const Home = () => {
  const metaData = {
    title: `Harness（ハーネス）｜アジャイル開発に必須、AIドリブンなCI/CDプラットフォーム｜Harness正規販売代理店 DXable`,
    url: `https://harness.dxable.com/`,
    description: `Harnessは、ビルド・テスト・デプロイ・検証を早い周期で継続的に回し、顧客やビジネスのニーズに適応したソフトウェアのアジャイル開発の促進を可能にするCI/CDツールです。`,
    keywords: `Harness,harness,Harness.io,harness.io,ハーネス,DevOps,CI,Continuous Integration,継続的インテグレーション,CD,Continuous Delivery,継続的デリバリー,Feature Flags,機能フラグ,CCM,クラウドコスト管理,Harness代理店`,
  };
  return (
    <>
      <Meta meta={metaData} />
      <Container>
        <Hero></Hero>
        <Description></Description>
        <Features></Features>
        <Articles></Articles>
      </Container>
    </>
  );
};
export default Home;
