import { useStaticQuery, graphql } from "gatsby";
import { useState, useEffect } from "react";
import moment from "moment";
const useHomeArticles = () => {
  const data = useStaticQuery(
    graphql`
      query {
        strapi {
          blogs(sort: "publishedAt:desc", pagination: { limit: 3 }) {
            data {
              attributes {
                title
                slug
                categories {
                  data {
                    attributes {
                      slug
                    }
                  }
                }
                publishedAt
                updatedAt
                releaseDate
              }
            }
          }
        }
      }
    `
  );

  let [allData, setAllData] = useState([]);
  let formatData = (data) => {
    let tmp = [];

    let { strapi } = data;
    strapi.blogs.data.map((item) => {
      let date = new Date(item.attributes.releaseDate);
      tmp.push({
        link: item.attributes.slug,
        title: item.attributes.title,
        category: item.attributes?.categories?.data[0]?.attributes?.slug,
        releasedate: date,
        type: "blogs",
      });
    });
    let sortedArray = tmp.sort((a, b) => {
      return b.releasedate - a.releasedate;
    });
    return sortedArray;
  };
  useEffect(() => {
    setAllData(formatData(data));
  }, []);
  return {
    data: allData,
  };
};
export default useHomeArticles;
