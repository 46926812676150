import React from "react";
import { Link } from "gatsby";
import useHomeArticles from "@hooks/useHomeArticles";
import arrow from "@images/home/arrow.svg";
import moment from "moment";
import "moment/locale/ja";
const Articles = () => {
  let { data } = useHomeArticles();
  let checkDx = (link) => {
    if (link.includes("http")) return "Harness 関連";
    else {
      return "コンテンツ更新情報";
    }
  };
  return (
    <section className="articles">
      <h2 className="articles__title">Harness の最新情報</h2>
      <p className="articles__description">
        Harness関連のお知らせ・コンテンツ更新情報​
      </p>
      <div className="articles__cards">
        {data.map((itm) => {
          return (
            <div key={itm.title} className="articles__cards__items">
              <div className="flex items-center justify-start w-full gap-4 md:contents">
                <p className="articles__cards__items__date">
                  {moment(itm.releasedate).format("ll")}
                </p>
                <button
                  className={`articles__cards__items__btn  ${
                    itm.type === "scrape"
                      ? "articles__cards__items__btn--alt"
                      : ""
                  }`}
                >
                  {checkDx(itm.link)}
                </button>
              </div>
              <Link
                target={itm.type === "scrape" ? "_blank" : ""}
                style={{ width: "100%" }}
                href={
                  itm.type === "scrape"
                    ? itm.link
                    : `/blog/${itm.category}/${itm.link}`
                }
              >
                <div className="gap-1 contents md:flex">
                  {itm.title}{" "}
                  {itm.link.includes("http") && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 inline md:block shrink-0"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                      <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                    </svg>
                  )}
                </div>
              </Link>
            </div>
          );
        })}
      </div>
      <div className="articles__btn__wrapper">
        <Link className="articles__btn" href="/blog/">
          ブログ一覧を見る
          <img
            className="w-6 object-contain aspect-square"
            objectFit="contain"
            src={arrow}
            alt="more"
          />
        </Link>
        <Link href="/news/" className="articles__btn">
          News一覧をみる​
          <img
            className="w-6 object-contain aspect-square"
            objectFit="contain"
            src={arrow}
            alt="more"
          />
        </Link>
      </div>
    </section>
  );
};
export default Articles;
