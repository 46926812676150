import React from "react";

const Description = () => {
  return (
    <div className="description">
      <h2 className="description__title">
        Harnessで開発・デプロイ・検証サイクルを高速に
      </h2>
      <p className="description__para">
        ﻿Harnessは顧客やビジネスのニーズに適応したソフトウェア開発を可能にするアジャイル開発を促進するためのツールです。
        アジャイル開発では、ソフトのビルド・テスト・デプロイ・検証を早い周期で継続的に回すことで、ニーズに合わせてソフトウェアを進化させることができます。その進化を早めるためのCI/CDツールがHarnessです。
      </p>
    </div>
  );
};
export default Description;
